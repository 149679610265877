import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { StateProvider } from "./store/store.js";
import { AppContextProvider } from "./context/AppContext";

import { SnackbarProvider, useSnackbar } from "notistack";
import ProjectManagementProvider from "./components/ProjectManagement/store/ProjectManagementProvider"
import DashboardProvider from "./components/dashboard/dashboardContext/DashboardProvider"


const loadConfig = async () => {
  if (process.env.NODE_ENV !== 'development') {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = '/vcc-config.js';
      document.head.appendChild(script);
      script.addEventListener('load', resolve);
    });
  }
};


const renderApp = (App) => {
  ReactDOM.render(
    <AppContextProvider>
      <ProjectManagementProvider>
        <DashboardProvider>
          <SnackbarProvider maxSnack={3}>
            <App />
          </SnackbarProvider>
        </DashboardProvider>
      </ProjectManagementProvider>
    </AppContextProvider>,
    document.getElementById("root")
  );
};

const initializeApp = async () => {
  await loadConfig();
  const AppModule = await import("./App");
  const App = AppModule.default;
  renderApp(App);  
};

initializeApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
