import React, { useReducer, createContext } from "react";
import { setCookie, deleteCookie } from "../utils/cookies";
import moment from "moment";

export const AppContext = createContext();
const localLang = navigator.language.toLocaleLowerCase().split("-")[0];
import(`moment/locale/${localLang}`).then(moment.locale(localLang));

export const setDates = (globalRange, timezone = null) => {
  if (globalRange) {
    switch (globalRange) {
      case "today":
        startDate = moment().startOf("day").toDate();
        endDate = moment().endOf("day").toDate();
        break;
      case "yesterday":
        startDate = moment().subtract(1, "days").startOf("day").toDate();
        endDate = moment().subtract(1, "days").endOf("day").toDate();
        break;
      case "last48h":
        startDate = moment().subtract(48, "hours").toDate();
        endDate = moment().toDate();
        break;
      case "last7days":
        startDate = moment().subtract(7, "days").toDate();
        endDate = moment().toDate();
        break;
      case "thisWeek":
        startDate = moment().startOf("isoweek").toDate();
        endDate = moment().endOf("isoweek").toDate();
        break;
      case "lastWeek":
        startDate = moment().subtract(1, "weeks").startOf("isoweek").toDate();
        endDate = moment().subtract(1, "weeks").endOf("isoweek").toDate();
        break;
      case "workWeek":
        startDate = moment().day("Monday").startOf("day").toDate();
        endDate = moment().day("Friday").endOf("day").toDate();
        break;
      case "lastWorkWeek":
        startDate = moment()
          .subtract(1, "weeks")
          .day("Monday")
          .startOf("day")
          .toDate();
        endDate = moment()
          .subtract(1, "weeks")
          .day("Friday")
          .endOf("day")
          .toDate();
        break;
    }
  }
};

export const getDatesWithTimezone = (globalRange, timezone) => {
  if (globalRange) {
    switch (globalRange) {
      case "today":
        startDate = moment.tz(timezone).startOf("day");
        endDate = moment.tz(timezone).endOf("day");
        break;
      case "yesterday":
        startDate = moment.tz(timezone).subtract(1, "days").startOf("day");
        endDate = moment.tz(timezone).subtract(1, "days").endOf("day");
        break;
      case "last48h":
        startDate = moment().subtract(48, "hours").tz(timezone);
        endDate = moment().tz(timezone);
        break;
      case "last7days":
        startDate = moment().subtract(7, "days").tz(timezone);
        endDate = moment().tz(timezone);
        break;
      case "thisWeek":
        startDate = moment().tz(timezone).startOf("isoweek");
        endDate = moment().tz(timezone).endOf("isoweek");
        break;
      case "lastWeek":
        startDate = moment()
          .tz(timezone)
          .subtract(1, "weeks")
          .startOf("isoweek");
        endDate = moment().tz(timezone).subtract(1, "weeks").endOf("isoweek");
        break;
      case "workWeek":
        startDate = moment().tz(timezone).day("Monday").startOf("day");
        endDate = moment().tz(timezone).day("Friday").endOf("day");
        break;
      case "lastWorkWeek":
        startDate = moment()
          .tz(timezone)
          .startOf("day")
          .day("Monday")
          .subtract(1, "weeks");
        endDate = moment()
          .tz(timezone)
          .endOf("day")
          .day("Friday")
          .subtract(1, "weeks");
        break;
    }
  }
  return { startDate: startDate, endDate: endDate };
};

var startDate = moment().subtract(2, "days");
var endDate = moment();

var globalRange = localStorage.getItem("vcc_global_range");
if (!globalRange) {
  globalRange = "last48h";
}

setDates(globalRange);

var dateFormat = localStorage.getItem("user_date_format");
var hourFormat = localStorage.getItem("user_hour_format");

if (!dateFormat) {
  dateFormat = "YYYY-MM-DD";
}
if (!hourFormat) {
  hourFormat = "HH:mm:ss";
}

const initialState = {
  user: null,
  is_admin: false,
  project: null,
  token: false,
  darkMode: false,
  engines: null,
  leftDrawerOpen: false,
  rightDrawerOpen: false,
  locale: localLang,
  globalTimeRange: globalRange,
  startDate: startDate,
  endDate: endDate,
  liveData: true,
  userProjects: null,
  labelLayer: null,
  sliderDateTime: null,
  dateFormat: dateFormat,
  hourFormat: hourFormat,
  reportPage: null,
  selectedMapElement: null,
  scopes_permissions: null,
  zoom_lvl: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "RELOAD_PAGE":
      // localStorage.setItem("AppContext", JSON.stringify(state));
      if (action.payload){
        // If there is a project id onm the payload, save it (changing from one project to another)
        localStorage.setItem("ChangedProject", action.payload);
      }
      window.location.reload();
      break;

    case "SIGN_IN":
      // localStorage.removeItem("AppContext");
      return {
        ...state,
        project: action.payload.project,
        userProjects: action.payload.userProjects,
        scopes_permissions: null,
        user: action.payload.user,
        token: action.payload.token,
        is_admin: action.payload.is_admin ?? false
      };
    case "LOAD_USER":
      return { 
        ...state, 
        user: action.payload, 
        is_admin: action.payload.is_admin ?? false 
      };
    case "SET_PRIMARY_PROJECT":
      let new_user = state.user;
      new_user.default_project_id = action.payload;

      return {
        ...state, user: new_user
      };

    case "TOGGLE_DARK_MODE":
      return { ...state, darkMode: !state.darkMode };
    case "SWITCH_DARK_MODE":
      return { ...state, darkMode: true };
    case "SWITCH_LIGHT_MODE":
      return { ...state, darkMode: false };
    case "LOAD_PROJECT":
      return { ...state, project: action.payload };
    case "LOAD_PROJECT_PERMISSIONS":
      return { ...state, scopes_permissions: action.payload };
    case "LOAD_USER_PROJECTS":
      return { ...state, userProjects: action.payload };
    case "LOAD_TOKEN":
      setCookie("tn_token", action.payload, 24 * 10);
      return { ...state, token: action.payload };
    case "LOGOUT":
      localStorage.clear();
      deleteCookie("tn_token");
      return { 
        ...state, 
        token: null, 
        user: null,
        project: null,
        userProjects: null,
        scopes_permissions: null,
        engines: null,
        is_admin: false
      };
    case "LOAD_PROJECT_ENGINES":
      return { ...state, engines: action.payload };
    case "LEFT_DRAWER_OPEN":
      return { ...state, leftDrawerOpen: true };
    case "LEFT_DRAWER_CLOSE":
      return { ...state, leftDrawerOpen: false };
    case "RIGHT_DRAWER_OPEN":
      return { ...state, rightDrawerOpen: true };
    case "RIGHT_DRAWER_CLOSE":
      return { ...state, rightDrawerOpen: false };
    case "TOGGLE_LANGUAGE":
      return { ...state, locale: action.payload };
    case "SET_LABEL_LAYER":
      return { ...state, labelLayer: action.payload };
    case "SET_SLIDER_DATETIME":
      return { ...state, sliderDateTime: action.payload };
    case "SET_LIVE_DATA":
      return { ...state, liveData: action.payload };
    case "SET_REPORT_PAGE_MODE":
      localStorage.setItem("report_page", action.payload);
      return { ...state, reportPage: action.payload };
    case "SET_DATE_FORMAT":
      localStorage.setItem("user_date_format", action.payload);
      return { ...state, dateFormat: action.payload };
    case "SET_HOUR_FORMAT":
      localStorage.setItem("user_hour_format", action.payload);
      return { ...state, hourFormat: action.payload };
    case "UPDATE_RANGE_DATES":
      var dates = getDatesWithTimezone(action.payload, state.project.timezone);
      return {
        ...state,
        startDate: dates.startDate,
        endDate: dates.endDate,
        globalRange: action.payload,
      };
    case "SET_GLOBAL_TIME_RANGE":
      localStorage.setItem("vcc_global_range", action.payload);
      setDates(action.payload);
      return {
        ...state,
        globalTimeRange: action.payload,
      };
    case "SET_SELECTED_MAP_ELEMENT":
       if (state.selectedMapElement === action.payload) {
        return { ...state, selectedMapElement: null};
      }
      return { ...state, selectedMapElement: action.payload };
    default:
      throw new Error();
  }
};

export const AppContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {props.children}
    </AppContext.Provider>
  );
};

export const AppContextConsumer = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Consumer value={[state, dispatch]}>
      {props.children}
    </AppContext.Consumer>
  );
};
