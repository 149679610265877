import React from 'react';

const ProjectManagementContext = React.createContext({

    projectCoords: {},
    projectZoom: '',
    projectName: '',
    routeCoords: '',
    routeDistance: '',
    selectedTypes: '',
    selectedCategories: '',
    addVector: false,
    vectorSource: '',
    vectorDestination: '',
    sensorAdded: false,
    sensorDeleted: false,
    radarAdded: false,
    radarDeleted: false,
    cameraAdded: false,
    cameraDeleted: false,
    vectorAdded: false,
    vectorDeleted: false,
    newDeviceLocation: '',
    sensorsBounds: null,
    radarsBounds: null,
    camerasBounds: null,
    vectorsBounds: null,
    gpsBounds: null,
    gpsLayer: null,
    gpsAdded: null,
    envstationsBounds: null,
    envstationsLayer: null,
    envstationAdded: false,
    envstationDeleted: false,
    daiBounds: null,
    daiLayer: null,
    daiAdded: false,
    daiDeleted: false,
    vmsDeleted: false,
    vmsAdded: false,
    vmsBounds: null,
    vmsLayer: null,
    chargerDeleted: false,
    chargerAdded: false,
    chargersBounds: null,
    chargerLayer: null,
    moveMap: true,
    btSensorsLayer: null,
    radarsLayer: null,
    camerasLayer: null,
    newVector: false


});

export default ProjectManagementContext;
